<script setup lang="ts">
import { onMounted } from 'vue'

onMounted(() => {
  const theme = localStorage.getItem('theme')
  if (theme) {
    document.documentElement.setAttribute('data-theme', theme)
  }
})
</script>
<template>
  <RouterView />
</template>
